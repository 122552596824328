.rootLayout {
    width: 100%;
    height: 100%;
}

.ant-layout-content {
    margin: 24px 16px;
    padding: 24px;
    background: #fff;
    min-height: 280px;
}
