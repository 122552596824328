.graphqlmenu-icon {
    padding-top: 5px;
    font-size: 20px;
    text-align: center;
    color: white;
    margin-top: auto;
    margin-bottom: 5px;
    align-self: center;
}

.graphqlmenu-sider {
    height: 100vh;
}

.ant-layout-sider-children {
    height: 100%;
    display: flex;
    flex-direction: column;
}