.RowAllowedToExpire {
    background: #bae637;
}
.RowExpirationNull {
    background: #bfbfbf;
}
.RowExpirationRed {
    background: #ff4d4f;
}
.RowExpirationOrange {
    background: #ffa940;
}
.RowExpirationYellow {
    background: #ffec3d;
}
.ant-table-tbody > tr > td,
.ant-table-thead > tr > th {
    padding: 5px;
    word-break: keep-all;
}
